@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  @apply md:bg-gray-200;
  font-family: "Source Sans Pro", "Ubuntu", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
